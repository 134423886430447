<template>
	<v-navigation-drawer
		v-model="drawer"
		fixed
		stateless
		temporary
		right
		class="answer-drawer"
	>
		<div class="d-flex align-center header pt-4 px-2 pb-2">
			<span
				class="mdi mdi-chevron-left back-icon"
				@click="closeDrawer()"
			></span>
			<h1 class="crud-title pl-2">Answer The Question</h1>
		</div>
		<v-row class="pa-4 mx-0 mb-8">
			<v-col cols="1" class="pa-0">
				<v-avatar>
					<img :src="question.author.profilePhotoUrl" />
				</v-avatar>
			</v-col>
			<v-col cols="11" class="pa-0 pl-4 d-flex flex-column mb-3">
				<div class="d-flex flex-row justify-space-between">
					<div class="d-flex flex-column">
						<p class="question font-weight-medium">
							{{ question.text }}
						</p>
						<p class="author">
							Asked by
							<span class="name">
								{{ question.author.displayName }}
							</span>
							on {{ dateFormat(question.updateTime) }}
						</p>
					</div>
				</div>
			</v-col>
		</v-row>
		<div class="d-flex flex-row justify-space-between total-answers mt-4">
			<v-chip outlined class="total">
				Top Answers {{ question.totalAnswerCount }}
			</v-chip>
		</div>
		<div v-if="question.topAnswers && question.topAnswers.length > 0">
			<div v-for="answer in question.topAnswers" :key="answer.name">
				<v-row
					class="mx-0 px-4"
					v-if="answer.author.type !== 'MERCHANT'"
				>
					<v-col cols="1" class="pa-0 d-flex justify-center">
						<v-avatar size="36">
							<img :src="answer.author.profilePhotoUrl" />
						</v-avatar>
					</v-col>
					<v-col cols="11" class="pa-0 d-flex flex-column">
						<v-card
							class="
								d-flex
								flex-row
								justify-space-between
								mb-0
								px-4
								py-2
							"
						>
							<div class="d-flex flex-column">
								<div class="mb-2">
									<span class="name">
										{{ answer.author.displayName }}
									</span>
									<p class="author">
										Replied on
										{{ dateFormat(answer.updateTime) }}
									</p>
								</div>
								<p class="answer">
									{{ answer.text }}
								</p>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</div>
		<v-row class="mx-0 mt-8 px-4">
			<v-col cols="1" class="pa-0 d-flex justify-center">
				<v-avatar size="36">
					<img
						src="https://www.gstatic.com/bfe/images/icons/default_merchant_avatar.svg"
					/>
				</v-avatar>
			</v-col>
			<v-col cols="11" class="pa-0 d-flex flex-column">
				<v-textarea
					outlined
					name="input-7-4"
					label="Your Reply"
					auto-grow
					@input="$v.answer.$touch()"
					@blur="$v.answer.$touch()"
					:error-messages="answerErrors()"
					v-model="answer"
				></v-textarea>
			</v-col>
		</v-row>
		<template v-slot:append>
			<div
				class="
					d-flex
					flex-row
					py-4
					px-10
					align-start
					justify-space-between
				"
			>
				<v-btn class="save" @click="updateAnswer()" :loading="saving">
					Save<span class="mdi mdi-check pl-2 icon"></span>
				</v-btn>
				<UnoDeleteButton
					v-if="answer"
					@confirm="deleteAnswer()"
					:deleting="deleting"
				></UnoDeleteButton>
			</div>
		</template>
	</v-navigation-drawer>
</template>
<script>
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
	components: {
		UnoDeleteButton,
	},
	props: {
		question: {
			type: Object,
			required: true,
		},
		saving: {
			type: Boolean,
			default: false,
		},
		deleting: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			drawer: true,
			answer: '',
			haveErrors: false,
		}
	},
	validations: {
		answer: {
			required,
			minLength: minLength(5),
		},
	},
	created() {
		if (this.question.topAnswers && this.question.topAnswers.length > 0) {
			this.question.topAnswers.forEach((answer) => {
				if (answer.author.type === 'MERCHANT') {
					this.answer = answer.text
					return false
				} else {
					return true
				}
			})
		}
	},

	methods: {
		answerErrors() {
			let errors = []
			if (!this.$v.answer.$dirty) return errors

			!this.$v.answer.minLength &&
				errors.push('Answer must be atleast 5 letters long')
			!this.$v.answer.required && errors.push('Answer is required.')
			if (errors.length > 0) this.haveErrors = true
			else this.haveErrors = false
			return errors
		},
		closeDrawer() {
			this.$emit('close-drawer')
		},
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
		updateAnswer() {
			if (!this.haveErrors) {
				let payload = {
					name: this.question.name,
					reply: this.answer,
				}
				this.$emit('on-answer-update', payload)
			}
		},
		deleteAnswer() {
			this.$emit('delete-answer', this.question.name)
		},
	},
}
</script>
<style scoped>
.answer-drawer {
	width: 60% !important;
	height: 100% !important;
	overflow-y: auto;
}
.answer-drawer .header {
	border-bottom: 1px solid #eee;
}
.answer-drawer .back-icon {
	font-size: 25px;
	cursor: pointer;
}
.answer-drawer .crud-title {
	width: 100%;
}
.answer-drawer .close-icon {
	font-size: 22px;
	cursor: pointer;
}
.errors {
	color: red;
	font-size: 13px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	/* width: 100% !important; */
}
.question {
	font-size: 18px;
}
.author {
	font-size: 14px;
}
.name {
	color: var(--blue);
}
.total-answers {
	margin-top: 10px;
	border-top: 1px solid #dfe1e5;
	padding-top: 0;
}
.total-answers .total.v-chip {
	position: relative;
	top: -19px;
	border: 1px solid #dfe1e5;
	padding: 18px 33px;
	border-radius: 24px;
	font-weight: 500;
	background-color: #fff !important;
	left: 24px;
	font-size: 15px;
}
</style>
