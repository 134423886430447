<template>
	<div class="pt-5">
		<div v-if="!noDataFound">
			<div v-if="isLoading" color="grey lighten-4" class="pa-3">
				<v-row>
					<v-col cols="12" v-for="i in 4" :key="i">
						<v-skeleton-loader
							class="mx-auto"
							width="100%"
							type="card"
						></v-skeleton-loader>
					</v-col>
				</v-row>
			</div>
			<v-row class="mx-0 px-4" v-else>
				<v-col
					cols="12"
					class="pa-0"
					v-for="question in questions"
					:key="question.name"
				>
					<v-card class="pa-0">
						<v-row class="mx-0 pa-4">
							<v-col cols="1" class="pa-0">
								<v-avatar>
									<img
										:src="question.author.profilePhotoUrl"
									/>
								</v-avatar>
							</v-col>
							<v-col cols="11" class="pa-0 d-flex flex-column">
								<div
									class="
										d-flex
										flex-row
										justify-space-between
										mb-3
									"
								>
									<div class="d-flex flex-column">
										<p class="question font-weight-medium">
											{{ question.text }}
										</p>
										<p class="author">
											Asked by
											<span class="name">
												{{
													question.author.displayName
												}}
											</span>
											on
											{{
												dateFormat(question.updateTime)
											}}
										</p>
									</div>
								</div>
							</v-col>
						</v-row>
						<div
							class="
								d-flex
								flex-row
								justify-space-between
								total-answers
								mt-4
							"
						>
							<v-chip outlined class="total">
								Top Answers {{ question.totalAnswerCount }}
							</v-chip>
						</div>
						<div
							v-if="
								question.topAnswers &&
								question.topAnswers.length > 0
							"
						>
							<v-row
								class="mx-0 px-4 mb-2"
								v-for="answer in question.topAnswers"
								:key="answer.name"
							>
								<v-col
									cols="1"
									class="pa-0 d-flex justify-center"
								>
									<v-avatar size="36">
										<img
											:src="answer.author.profilePhotoUrl"
										/>
									</v-avatar>
								</v-col>
								<v-col
									cols="11"
									class="pa-0 d-flex flex-column"
								>
									<v-card
										class="
											d-flex
											flex-row
											justify-space-between
											mb-0
											px-4
											py-2
										"
									>
										<div class="d-flex flex-column">
											<div class="mb-2">
												<span class="name">
													{{
														answer.author
															.displayName
													}}</span
												>
												<p class="author">
													Replied on
													{{
														dateFormat(
															answer.updateTime
														)
													}}
												</p>
											</div>
											<p class="answer">
												{{ answer.text }}
											</p>
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
						<v-row class="mx-0 mt-2 px-4">
							<v-col cols="1" class="pa-0 d-flex justify-center">
								<v-avatar size="36">
									<img
										src="https://lh6.googleusercontent.com/-Ad-teg8hVuY/AAAAAAAAAAI/AAAAAAAAADM/AMZuuclWkao2oaq-lb35hRo3H7UYB_bpKA/c0x00000000-cc-rp/photo.jpg"
									/>
								</v-avatar>
							</v-col>
							<v-col
								cols="11"
								class="pa-0 d-flex flex-column"
								@click="openDrawer(question)"
							>
								<v-text-field
									placeholder="Write Your Answer"
									rounded
									dense
									outlined
									disabled
								></v-text-field>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
				<div v-if="openAnswerDrawer">
					<AnswerDrawer
						:question="question"
						@close-drawer="closeDrawer()"
						@on-answer-update="updateQuestion($event)"
						@delete-answer="deleteAnswer($event)"
						:saving="isSaving"
						:deleting="deleting"
					></AnswerDrawer>
				</div>
			</v-row>
			<div class="d-flex flex-row justify-center ma-2">
				<v-btn
					@click="getQuestions(previousPageToken, true, false)"
					:disabled="!previousPageToken"
					:loading="btnLoading"
					class="mr-2"
				>
					<span class="mdi mdi-arrow-left-bold"></span
				></v-btn>
				<v-btn
					@click="getQuestions(nextPageToken, false, true)"
					:disabled="!nextPageToken"
					:loading="btnLoading"
					><span class="mdi mdi-arrow-right-bold"></span
				></v-btn>
			</div>
		</div>
		<NoData v-else></NoData>
	</div>
</template>
<script>
import AnswerDrawer from './AnswerDrawer'
import NoData from '../../../components/NoData'
export default {
	components: {
		AnswerDrawer,
		NoData,
	},
	data() {
		return {
			btnLoading: false,
			page: 1,
			repeat: false,
			pageTokens: [],
			nextPageToken: null,
			previousPageToken: null,
			isLoading: false,
			openAnswerDrawer: false,
			question: {},
			questions: [],
			isSaving: false,
			deleting: false,
			noDataFound: false,
		}
	},
	created() {
		this.getQuestions()
	},
	computed: {
		locationName() {
			return this.$cookies.get('gmb-selected-location')
		},
		locationEmail() {
			return this.$cookies.get('gmb-account-email')
		},
	},
	methods: {
		getQuestions(nextPageToken, previous, next) {
			this.isLoading = true
			const projectId = this.$store.state.app.projectId
			this.btnLoading = true
			let payload = {
				name: this.locationName,
				email: this.locationEmail,
				pageToken: nextPageToken ? nextPageToken : null,
			}
			if (nextPageToken === 'last') payload.pageToken = null
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/qa/questions`,
				data: payload,
			})
				.then((response) => {
					this.isLoading = false
					this.repeat = true
					this.btnLoading = false
					this.$nextTick(function () {
						this.questions = response?.data?.data?.questions
						this.questions
							? (this.noDataFound = false)
							: (this.noDataFound = true)
					})
					if (next) {
						response?.data?.data?.nextPageToken
							? this.pageTokens.push(
									response.data.data.nextPageToken
							  )
							: null
						this.previousPageToken =
							this.pageTokens?.length <= 1
								? null
								: this.pageTokens[this.pageTokens.length - 2]
					}
					this.nextPageToken = response?.data?.data?.nextPageToken
						? response.data.data.nextPageToken
						: null
					if (previous && this.previousPageToken) {
						this.pageTokens.splice(this.pageTokens.length - 1, 1)
					}
					this.previousPageToken =
						this.pageTokens.length === 0
							? null
							: this.pageTokens[this.pageTokens.length - 2]
					if (this.repeat) {
						this.previousPageToken =
							this.pageTokens.length === 1
								? 'last'
								: this.pageTokens[this.pageTokens.length - 2]
					}
				})
				.catch((err) => {
					if (err.response.status == 404) this.noDataFound = true
					this.isLoading = false
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
		dateFormat(date) {
			const d = new Date(date)
			const day = parseInt(d.toLocaleString('en', { day: 'numeric' }))
			const month = d.toLocaleString('en', { month: 'short' })
			const year = d.toLocaleString('en', { year: '2-digit' })
			let suffix = ''
			if (day === 1) {
				suffix = 'st'
			} else if (day === 2) {
				suffix = 'nd'
			} else if (day === 3) {
				suffix = 'rd'
			} else {
				suffix = 'th'
			}
			return `${day}${suffix} ${month}, ${year}`
		},
		openDrawer(question) {
			this.openAnswerDrawer = true
			this.question = question
		},
		closeDrawer() {
			this.openAnswerDrawer = false
		},
		updateQuestion(answerObject) {
			this.isSaving = true
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/qa/answer/post`,
				data: {
					...answerObject,
					email: this.locationEmail,
				},
			})
				.then((response) => {
					this.isSaving = false
					this.openAnswerDrawer = false
					if (response?.data?.success) {
						this.$snackbar.notify({
							message: response?.data?.message,
							color: 'green',
						})
						setTimeout(() => {
							this.$snackbar.notify({
								message:
									'Questions will be updated after a while.',
							})
						}, 5000)
						setTimeout(() => {
							this.getQuestions()
						}, 20000)
					}
				})
				.catch((err) => {
					this.isLoading = false
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
		deleteAnswer(answerObject) {
			this.deleting = true
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/qa/answer/delete`,
				data: {
					name: answerObject,
					email: this.locationEmail,
				},
			})
				.then((response) => {
					this.deleting = false
					this.openAnswerDrawer = false
					this.$snackbar.notify({
						message: response?.data?.message,
						color: 'yellow',
					})
					setTimeout(() => {
						this.$snackbar.notify({
							message: 'Questions will be updated after a while.',
						})
					}, 5000)
					if (response?.data) {
						setTimeout(() => {
							this.getQuestions()
						}, 20000)
					}
				})
				.catch((err) => {
					this.isLoading = false
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
		},
	},
}
</script>
<style scoped>
.question {
	font-size: 18px;
}
.author {
	font-size: 14px;
}
.name {
	color: var(--blue);
}
.total-answers {
	margin-top: 10px;
	border-top: 1px solid #dfe1e5;
	padding-top: 0;
}
.total-answers .total.v-chip {
	position: relative;
	top: -19px;
	border: 1px solid #dfe1e5;
	padding: 18px 33px;
	border-radius: 24px;
	font-weight: 500;
	background-color: #fff !important;
	left: 24px;
	font-size: 15px;
}
</style>
